$('body').on('fx-ready', function() {
    var options = {
        kontrastAktivClass: 'hoher-kontrast',
        kontrastButtonSelektor: '.js-hoher-kontrast'
    };

    var $document = $('html');
    var $button = $document.find(options.kontrastButtonSelektor);

    // Überprüfe evtl. vorhandene Nutzerpräferenz von früherem Besuch
    var istHoherKontrastPraeferiert = (Cookies.get("hoherkontrast") === "y");

    /**
     * Aktiviere Hohen-Kontrast-Modus
     * @param {jQuery} $elem
     */
    var aktiviereHohenKontrast = function($elem) {
        $elem.addClass(options.kontrastAktivClass);
        $button.text('Normaler Kontrast');
    };

    /**
     ** Deaktiviere Hohen-Kontrast-Modus
     * @param {jQuery} $elem
     */
    var deaktiviereHohenKontrast = function($elem) {
        $elem.removeClass(options.kontrastAktivClass);
        $button.text('Hoher Kontrast');
    };

    /**
     * Verwalte Änderung der Nutzerpräferenz
     * @param {jQuery} $elem
     */
    var aendereKontrastPraeferenz = function($elem) {
        if ($elem.hasClass(options.kontrastAktivClass)) {
            deaktiviereHohenKontrast($elem);
            Cookies.remove("hoherkontrast");
        } else {
            aktiviereHohenKontrast($elem);
            Cookies.set("hoherkontrast", "y", { expires: 3650 });
        }
    };

    // Entspreche der Nutzerpräferenz, falls vorhanden
    if (istHoherKontrastPraeferiert) {
        aktiviereHohenKontrast($document);
    }

    // Registriere event handler für Änderungen der Nutzerpräferenz
    $button.on('click', function(e) {
        // Temporärer Fix für ungewolltes Triggern bei Klick auf Sysnav-Links
        if (!$(e.target).is('a')) {
            aendereKontrastPraeferenz($document);
        }
    });
});
