(function(H) {
    /**
     * Skript zur Bereitstellung von client-side inclusion via custom element <h-include>
     *
     * Anwender fordern die Funktionalität an, indem sie "gba.clientSideInclusion.hinclude"
     * per inline JS im DOM auf "true" setzen. [1]
     *
     * Beispiel-Implementierung: src/Gba/Bundle/WebsiteBundle/Resources/views/Shortcode/ais-download.html.twig
     *
     * Anwender müssen außerdem selbst dafür Sorge tragen, dass zwei weitere Skript-Dateien in /js für den
     * Browser abrufbar sind [2,3]:
     *
     * - document-register-element.js (src: node_modules/document-register-element/build/document-register-element.js)
     * - h-include.js (src: node_modules/h-include/lib/h-include.js)
     *
     * Typischerweise heißt das, dass wir im gulpfile zwei weitere files erzeugen, z. B. so:
     *
     * "javascripts": {
     *  "files": {
     *      "js/document-register-element.js": [
     *          "../node_modules/document-register-element/build/document-register-element.js"
     *      ],
     *      "js/h-include.js": [
     *          "../node_modules/h-include/lib/h-include.js"
     *      ],
     *      "js/scripts.js": [
     *          …
     *      ]
     *  }
     */

    'use strict';

    /**
     * Erzeuge den "gba" namespace falls nicht bereits vorhanden, und erzeuge einen
     * clientSideInclusion sub-namespace.
     */
    window.gba = window.gba || {};
    window.gba.clientSideInclusion = window.gba.clientSideInclusion || {};

    /**
     * Helferfunktion, um externe Skripte (optional async) zu laden und
     * onload (optional) einen Callback auszuführen.
     *
     * @param {Object} options
     * @callback [callback]
     */
    function loadScript(options, callback) {
        var script = document.createElement("script");
        script.src = options.src;
        script.async = options.async || false;
        if (callback) script.onload = callback;
        H.appendChild(script);
    }

    function loadHIncludeRegisterer() {
        loadScript({
            src: "/js/h-include.js", // [3]
            async: true
        });
    }

    // Checke, ob abhängige hincludes client-side inclusion machen möchten. [1]
    if (gba.clientSideInclusion.hinclude) {
        // Lade Custom Elements v1 polyfill, falls nötig…
        if (!window.customElements) {
            loadScript({
                src: "/js/document-register-element.js" // [2]
            }, function() {
                // … und im success Callback dann h-include.js, falls nötig
                if (!window.customElements.get('h-include')) {
                    loadHIncludeRegisterer();
                }
            });

        // … sonst lade direkt h-include.js, falls nötig
        } else if (!window.customElements.get('h-include')) {
            loadHIncludeRegisterer();
        }
    }

})(document.head);
