$('body').on('fx-ready', function() {
    $('.fx-targeting').each(function() {
        var targetingZone = $(this);
        var link = targetingZone.find('a.fx-targeting-target');

        if (!link.length) {
            link = targetingZone.find('a:first');
        }

        if (link.length) {
            if (link.attr('title')) {
                targetingZone.attr('title', link.attr('title'));
            }

            targetingZone.click(function(event) {
                if (event.target != link.get(0)) {
                    if (link.triggerHandler('click') !== false) {
                        window.location.href = link.attr('href');
                    }
                }
            });
        } else {
            targetingZone.attr('class', targetingZone.attr('class').replace(/fx-targeting[-\w]*/g, ''));
        }
    });
});
