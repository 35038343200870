(function($) {
    var $window = $(window);
    var $toTopLink = $('.js-to-top-link');
    var threshold = 600;

    // Bereite Anzeige eines 'fixed' positionierten to-top-links auf kleinen Bildschirmen vor (über CSS gesteuert).
    // Der Link wird aus Platz-/Ästethikgründen nur vom Footer abgekoppelt, wenn
    // - eine Mindestmenge an Pixeln nach unten gescrollt wurde
    // - eine "nach-oben-Scroll"-Bewegung eingeleitet wird
    if(im.lessThan('screen-sm')) {
        scrollDir();

        $window.on('scroll', function() {
            requestAnimationFrame(function() {
                var y = window.scrollY || window.pageYOffset;

                if(y > threshold) {
                    $toTopLink.addClass('should-detach');
                } else {
                    $toTopLink.removeClass('should-detach');
                }
            });
        });
    }

})(jQuery);