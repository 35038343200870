(function($) {
    /**
     * Skript zur Verbesserung der Auspunkt-Magie für den Sonderfall "Letzte Änderungen"-Unterseite
     *
     * Setzt max-width des vorletzten Breadcrumbs auf Breite des Inhalts plus Puffer für Abstand zum '//' Trennzeichen,
     * damit breadcrumbs generell via CSS Flexbox grow=1 sein können, aber in Fällen, wo der vorletzte Crumb sehr kurz ist,
     * trotzdem kein unnötiger white-space entsteht (flex-grow ist da nur semi-intelligent und würde folgendes erzeugen):
     * Start // Crumb // kurzer secondToLastCrumb                                                  // last Crumb
     */

    // Steige aus, wenn es nicht der Sonderfall ist
    if (!$('.js-page-body').hasClass('js-letzte-aenderungen')) {
        return;
    }

    var $breadcrumbItems = $('.js-breadcrumb');
    var $secondToLastCrumb = $($breadcrumbItems[$breadcrumbItems.length - 2]);
    var $secondToLastCrumbCaption = $secondToLastCrumb.find('.js-breadcrumb-caption');

    var crumbFontSize = parseFloat($secondToLastCrumb.css("font-size"));
    var trennerIconWidth = emToPx(parseFloat(getComputedStyle($secondToLastCrumb.get(0)).getPropertyValue('--trenner-icon-width')));
    var captionlabelContentWidth;

    if ($secondToLastCrumbCaption) {
        $secondToLastCrumb.css('max-width', '75%'); // begrenze die maximale Breite so, dass der letzte Crumb noch dahinter anfangen kann
        captionlabelContentWidth = $secondToLastCrumbCaption.width();
    }

    // setze explizit Breite der Caption plus Breite des Trenners, damit das Auspunkten funktioniert
    $secondToLastCrumb.css('max-width', captionlabelContentWidth + trennerIconWidth);

    // aus unklaren Gründen braucht es das nicht nur in IE sondern auch Chrome, sonst wird der Content ausgepunktet…
    $secondToLastCrumbCaption.css('width', captionlabelContentWidth + 4);

    function emToPx(input) {
        return (Math.floor(input * crumbFontSize));
    }
})(jQuery);
