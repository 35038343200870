/**
 * Fokussiere das angesprungene Eingabfeld nach Betätigung eines Sprungklinks
 * im Flash mit der Liste aller fehlerhaften Formularfelder.
 *
 * Hintergrund: wir setzen den Sprunglink auf die form-row, damit auch Label und
 * Fehlermeldung im sichtbaren Bereich des Browsers sind.
 */
(function($) {
    'use strict';

    var $formErrorSprunglinks = $('.js-form-error-sprunglink');

    $formErrorSprunglinks.on('click', function() {
        var sprunglinkZiel = $(this).attr('href');
        var inputId = sprunglinkZiel.replace(/gba-form-row-/g,'');

        // Setze micro-timeout, weil sonst das Fokusmanagement des Browsers noch damit beschäftigt ist,
        // den Fokus auf das Sprunglinkziel (den row-Container des Feldes) zu setzen, und die JS-Anweisung
        // nicht ausgeführt wird.
        window.setTimeout(function() {
            $(inputId).focus();
        }, 100);
    });

})(jQuery);
