var appState = {
    state: {},
    callbacks: [],

    init: function() {
        if (document.location.hash) {
            this.state = this._unserialize(document.location.hash.substring(1));
        }
        this.resume();
    },

    update: function(next) {
        var state = $.extend({}, this.state);
        $.extend(state, next);
        var newState = {};
        $.each(state, function(name, value) {
            if (value) {
                newState[name] = value;
            }
        });
        this.state = newState;
        var hash = this._serialize(this.state);
        if (hash == '')
            hash = 'n';
        document.location.replace('#' + hash);
    },

    get: function() {
        return $.extend({}, this.state);
    },

    resume: function(initially) {
        var state = this.state;
        $.each(this.callbacks, function() {
            this(state, initially);
        });
    },

    register: function(callback, context) {
        this.callbacks.push(callback);
        $(context).data('appStateCallbacks', $.merge($.makeArray($(context).data('appStateCallbacks')), [callback]));
    },

    unregister: function(callback, context) {
        var callbacks = [];
        this.callbacks = $.grep(this.callbacks, function(c) {
            return c !== callback;
        });
        $(context).data('appStateCallbacks', $.grep($.makeArray($(context).data('appStateCallbacks')), function(c) {
            return c !== callback
        }));
    },

    _serialize: function(appState) {
        var result = [];
        $.each(appState, function(name, value) {
            result.push(name);
            result.push(value);
        });
        return result.join('/');
    },

    _unserialize: function(result) {
        var appState = {};
        var name;
        $.each(result.split('/'), function() {
            if (!name) {
                name = this;
            } else {
                appState[name] = this;
                name = false;
            }
        });
        return appState;
    }
};
appState.init();

$('body').on('fx-ready', function(event) {
    window.setTimeout(function() {
        appState.resume(true);
    }, 0);
});

$('body').on('fx-remove', function(event) {
    $.each($.makeArray($(event.target).data('appStateCallbacks')), function() {
        appState.unregister(this, event.target);
    })
});
