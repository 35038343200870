(function($) {
    /**
     * Fix für Flexbug #75 in IE10/11
     * siehe: https://github.com/philipwalton/flexbugs/issues/75#issuecomment-375416714
     *
     * Dieses Skript dient dazu, falschen whitespace in IE10/11 zu vermeiden, wenn Bilder in flex-children mit
     * max-width durch den Browser verkleinert werden. IE scheint nicht zu repainten, nachdem die (ursprünglich
     * größeren) Bilder sich an ihren parent container angepasst haben und zeigt viel whitespace unter den
     * Bildern an.
     *
     * Dieses Verhalten ist normalerweise mit min-height: 1px; auf dem flex-container unterdrückbar, außer:
     * - die flexbox ist Kindeskind einer "globalen" flexbox, mit der ein sticky footer erzeugt wird
     * UND
     * - die Breite der flex-children ist in % definiert
     *
     * Setzt man für die flex-children eine feste Breite in px, tritt das Problem nicht auf. Also machen wir das
     * für IE und die Komponente "Reihe", in der es auftritt.
     */

    'use strict';

    function isIE() {
        return window.navigator.userAgent.match(/(MSIE|Trident)/);
    }

    if (isIE() || true) {
        // finde die flex-children, deren Breite wir fix setzen müssen:
        // nämlich nur Reiheninhalte, die Bilder beinhalten
        var $reiheInhaltMitImg = $('[class^="js-reihe__inhalt-"]').has('img');

        $reiheInhaltMitImg.each(function() {
            var $this = $(this);
            var width = $this.width();

            if (width > 0) {
                $this.css('width', width);
            }
        });
    }
})(jQuery);
