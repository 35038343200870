(function() {
    /**
     * Initialisiere https://github.com/scottaohara/a11y_tab_widget
     */
    'use strict';

    var widget = '[data-atabs]';
    var els = document.querySelectorAll(widget);

    // Generate all Tab Widget instances
    for (var i = 0; i < els.length; i++) {
        var nTabs = new ARIAtabs(els[i], {
            'tabListClass': 'gba-tabs__tablist',
            'tabClass': 'gba-tabs__tab',
            'tabDisabledClass': 'gba-tabs__tab--disabled',
            'panelClass': 'gba-tabs__panel',
            'panelDisabledClass': 'gba-tabs__panel--disabled'
        });
    }
})();
