$('body').on('fx-ready', function() {
    // init wfDisclosure auf via CKE erstellten Ausklappbaren Abschnitten
    // Achtung: die Buttontexte werden vom CKE-Widget sprachspezifisch (de/en)
    // via data-attr gesetzt; die Texte hier gelten daher nur für in views
    // definierte ausklappbare Abschnitte
    $.wfDisclosure({
        buttonStylingClass: 'gba-ausklappbarer-abschnitt__button',
        buttonTextDisclose: 'mehr anzeigen',
        buttonTextHide: 'weniger anzeigen',
        buttonPositionBelow: false
    });
});
