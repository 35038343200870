(function($) {
    'use strict';

    // Desktop Nav implentiert nur die Logik des Megamenüs, des ersten Navigationspunkts "Über den G-BA".
    // Die gewählten Namen für Variablen usw. machen auf den ersten Blick einen anderen Eindruck.
    // In der folge lies "dropdown" als "megamenu".

    var $window = $(window);
    var mobileNavInitiated;
    var desktopNavInitiated;

    var desktopNav = {
        dropdownIsOpen: false,
        dropdownNavIsOpenClass: 'gba-main-nav__item--is-open',
        dropdownNavCloseButton: $('<button class="gba-main-nav__menu-close-button"><span>Menü schließen<span></button>'),
        dropdownNavIsOpen: false,
        init : function () {

            desktopNav.dropdownNavRoot = $('.js-gba-main-nav__root');
            desktopNav.dropdownNavItem = $('.gba-main-nav__item--is-mega-dropdown');
            desktopNav.dropdownNavLink = desktopNav.dropdownNavItem.find('.js-gba-main-nav__text');
            desktopNav.dropdownNavList = desktopNav.dropdownNavItem.find('.gba-main-nav__list--level-1');


            // Unter Umständen ist das Element von desktopNav.dropdownNavLink kein <a> sondern ein <span>.
            // Z.B. wenn der Menüpunkt "Über den G-BA" über die Breadcrumbs Navigation navigiert wurde.
            // Für diese Fälle muss das <span>-Element fokussierbar sein.
            desktopNav.dropdownNavLink
                .attr('tabindex', function() {
                    if ($(this).is('span')) return 0
                })
                .attr('aria-haspopup', true)
                .attr('aria-expanded', false);

            desktopNav.dropdownNavCloseButton
                .insertAfter(desktopNav.dropdownNavRoot)
                .hide()
                .click(function() {
                    desktopNav.dropdownIsOpen = false;
                    desktopNav.dropdownNavItem.removeClass(desktopNav.dropdownNavIsOpenClass);
                    desktopNav.dropdownNavLink.attr('aria-expanded', desktopNav.dropdownIsOpen);
                    $(this).hide();
                    desktopNav.dropdownNavLink.focus();
                })
                .blur(function () {
                    desktopNav.dropdownNavLink.focus();
                });

            desktopNav.dropdownNavLink.on('click keypress', function(e) {
                // Fange keypress events ab, für den Fall das das Toggle-Element ein span ist
                if(e.type === 'keypress' && e.which !== 13) return;  // the enter key code

                e.preventDefault();

                desktopNav.dropdownIsOpen = !desktopNav.dropdownIsOpen;
                desktopNav.dropdownNavItem.toggleClass(desktopNav.dropdownNavIsOpenClass);
                $(this).attr('aria-expanded', desktopNav.dropdownIsOpen);

                if (desktopNav.dropdownIsOpen) {
                    desktopNav.dropdownNavCloseButton.show();
                } else {
                    desktopNav.dropdownNavCloseButton.hide();
                }
            });

            // Initiere Tastatur-Bedienbarkeit
            desktopNav.dropdownKeyboardNav(desktopNav.dropdownNavItem, desktopNav.dropdownNavList, desktopNav.dropdownNavLink);

            return true;
        },
        destroy : function() {

            desktopNav.dropdownIsOpen = false;
            desktopNav.dropdownNavLink.off('click');
            desktopNav.dropdownNavItem.off('keydown').removeClass(desktopNav.dropdownNavIsOpenClass);
            desktopNav.dropdownNavLink.removeAttr('aria-haspopup');
            desktopNav.dropdownNavLink.removeAttr('aria-expanded');
            desktopNav.dropdownNavCloseButton.remove();

            return false;
        },
        dropdownKeyboardNav : function (menuItem, menu, target) {

            var $allLinksInsideDropdown = menuItem.find('a');
            var $first = $allLinksInsideDropdown.first();
            var $last = $allLinksInsideDropdown.last();
            var $submenus = menu.children('li');
            $submenus = $submenus.add('[class*=js-reihe]');

            menuItem.on('keydown', function(event) {
                var $target = $(event.target);
                var key = event.which.toString();
                var index = $allLinksInsideDropdown.index($target);
                var $next = $($allLinksInsideDropdown[index+1]);
                var $prev = $($allLinksInsideDropdown[index-1]);

                // Wenn Pfeiltasten gedrückt werden das Default-Verhalten verhindert.
                // Key Value der Pfeiltasten:
                // 37 links
                // 39 rechts
                // 38 oben
                // 40 unten

                if (key === '37' || key === '38' || key === '39' || key === '40') {
                    event.preventDefault();
                }

                switch (key) {

                    // Pfeil nach oben bzw. unten fokussiert das vorherige bzw. nächste Objekt
                    // Pfeil nach oben auf dem ersten Element bewirkt das Zurückgehen in der Tab-Reihenfolge auf das Ursprungselement
                    // Pfeil nach unten auf dem letzten Element fokussiert den Menü-Schliessen Button
                    case '40': // Pfeil nach unten
                        if($target[0] === $last[0]) {
                            desktopNav.dropdownNavCloseButton.focus();
                        } else {
                            $next.focus();
                        }
                        break;

                    case '38': // Pfeil nach oben
                        if($target[0] === $first[0]) {
                            target.focus();
                        } else {
                            $prev.focus();
                        }
                        break;

                    // Pfeil nach links bzw. rechts fokussiert das erste Link-element des vorherigen bzw.
                    // nächsten Geschwister-Elements des nächsthöheren Eltern-Elements
                    case '37': // Pfeil nach links
                        $target.closest($submenus).prev().find('a').first().focus();
                        break;

                    case '39': // Pfeil nach Rechts
                        $target.closest($submenus).next().find('a').first().focus();
                        break;

                    // Tab fokussiert den Menü-Schliessen-Button
                    case '9': // Tab
                        if($target[0] === $last[0]) {
                            event.preventDefault();
                            desktopNav.dropdownNavCloseButton.focus();
                        }
                        break;
                }
            });
        }
    };

    var mobileNav = {
        'context' : $('.js-gba-page'),
        'mainNavSkiplink' : $('.js-main-nav-skiplink'),
        'subNavSkiplink' : $('.js-sub-nav-skiplink'),
        'mainNav' : $('.js-gba-main-nav'),
        'navToggle' : $('.js-gba-navigations-toggle'),
        'navToggleContainer' : $('.js-gba-navigations-container'),
        'navWrapper' : $('.js-gba-header__navigations'),
        'navIsOpen' : false,
        'init' : function() {

            // Setze resize-handler auf window Object
            $window.on('resize', function () {

                // Erzeuge ein Event Object mit relevanten Eigenschaften für
                // das Manipulieren abhängiger Objekte/Elemente
                mobileNav.navResizeEvent = $.Event('gba.windowResized', {

                    'windowWidth' : $window.width(),
                    'windowHeight' : $window.height()
                });

                mobileNav.navWrapper.trigger(mobileNav.navResizeEvent);

            });

            // Setze click-handler auf toggle Object
            mobileNav.navToggle.click(function() {

                var $self = $(this);

                // Toggle den State der navigation
                mobileNav.navIsOpen = !mobileNav.navIsOpen;

                // Toggle das aria-expanded Attribut
                $self.attr('aria-expanded', mobileNav.navIsOpen);

                // Erzeuge ein Event Object mit relevanten Eigenschaften für
                // das Manipulieren abhängiger Objekte/Elemente
                mobileNav.navToggleEvent = $.Event('gba.navToggled', {

                    'target' : $self,
                    'windowWidth' : $window.width(),
                    'windowHeight' : $window.height()

                });

                // Setze den Event auf das Nav-Wrapper Element ab
                mobileNav.navWrapper.trigger(mobileNav.navToggleEvent);
            });

            // Setze das aria-label des Nav Toggle Containers entsprechend dem State der Navigation
            mobileNav.navToggleContainerOpenText = mobileNav.navToggleContainer.attr('aria-label');
            mobileNav.navToggleContainerCloseText = mobileNav.navToggleContainer.attr('data-nav-schliessen-text');

            mobileNav.navToggleContainer.on('click', function() {
                $(this).attr('aria-label', mobileNav.navIsOpen ? mobileNav.navToggleContainerCloseText : mobileNav.navToggleContainerOpenText);
            });

            // Das Wrapper Element horcht auf die gba-events
            mobileNav.navWrapper
                .on('gba.navToggled gba.windowResized', function (event) {

                    var $self = $(this);
                    var headerHeight = $('.js-gba-header').outerHeight(true);

                    if (mobileNav.navIsOpen) {

                        $self
                        // Zeige das Wrapper Element
                            .show()
                            // Passe die Dimensionen des Elements dem Viewport an. Der G-BA-Header bleibt sichtbar.
                            .css({
                                'width' : event.windowWidth,
                                'height' : event.windowHeight - headerHeight,
                                'position' : 'fixed',
                                'left' : 0,
                                'top' : headerHeight,
                                'z-index' : 99,
                                'overflow-y' : 'scroll'
                            });

                    } else if
                    // Entferne das 'style'-Attribut um das Element wieder auf seinen initialen Status zurückzusetzen
                    (event.namespace === 'navToggled') $self.removeAttr('style');
                });

            // Events 'bubblen' nach oben zum Kontext-Element
            mobileNav.context
                .on('gba.navToggled gba.windowResized', function (event) {

                    var $self = $(this);
                    var $footer = $self.siblings('.js-gba-footer');

                    if (mobileNav.navIsOpen) {
                        $self
                        // Passe die Dimensionen des Elements dem Viewport an, damit die
                        // Seite nicht gescrollt werden kann während die Navigation geöffnet ist.
                            .css({
                                'width' : event.windowWidth,
                                'height' : event.windowHeight,
                                'min-height' : event.windowHeight,
                                'position' : 'fixed',
                                'overflow' : 'hidden'
                            });

                        // Blende Footer aus, da er durch die sticky-footer-Lösung mit neg. margin nicht mehr im Context-Wrapper liegt
                        $footer.hide();

                    } else if (event.namespace === 'navToggled') {
                        $self.removeAttr('style'); // Entferne das 'style'-Attribut, um das Element wieder auf seinen initialen Status zurückzusetzen
                        $footer.show();
                    }

                });

            // Rufe die Methode zum Bauen der Dropdowns
            mobileNav.dropdowns.build();

            // Rufe die Methode zum Aufklappen des aktiven Pfades
            mobileNav.dropdowns.showActivePath();

            // Aktiviere mobile Navigation, wenn der Skiplink benutzt wird
            // Use Case: Nutzer*innen, die starken page zoom nutzen
            mobileNav.mainNavSkiplink.on('click', function(e) {
                e.preventDefault();
                mobileNav.navToggle.click();
                mobileNav.navWrapper.attr('tabindex', -1).focus();
            });

            // Aktiviere mobile Navigation auch dann, wenn der Skiplink zur 2. Ebene benutzt wird
            // Fokussiere direkt in die Navigation (überspringt Sysnav-Links)
            // Use Case: Nutzer*innen, die starken page zoom nutzen
            mobileNav.subNavSkiplink.on('click', function(e) {
                // Achtung: der Sonderfall "mobile Ansicht der Themen-Übersicht" wird in gba.themen.js behandelt
                if (!window.gba.themen.isUebersicht) {
                    e.preventDefault();
                    mobileNav.navToggle.click();
                    mobileNav.mainNav.attr('tabindex', -1).focus();
                }
            });

            mobileNav.keyboardNav();

            return true;
        },
        'hide' : function() {

            if (mobileNav.navIsOpen) {

                mobileNav.navToggle.trigger('click');

            }

            mobileNav.dropdowns.list.removeAttr('style');
            mobileNav.dropdowns.buttons.hide();

        },
        'show' : function() {

            mobileNav.dropdowns.buttons.show().removeClass(mobileNav.dropdowns.buttonIsActiveClass);
            mobileNav.dropdowns.list.hide();
            mobileNav.dropdowns.showActivePath();

        },
        'dropdowns' : {
            'text' : {
                'oeffnen' : 'Untermenü öffnen',
                'schliessen' : 'Untermenü schliessen'
            },
            'html' : function () {
              return  '<button class="gba-main-nav__child-list-toggle"><span>' + mobileNav.dropdowns.text.oeffnen + '</span></button>'
            },
            'buttons' : undefined,
            'buttonIsActiveClass': 'gba-main-nav__child-list-toggle--is-active',
            'list' : $('.gba-main-nav__list').not('.gba-main-nav__list--level-0'),

            // Einmalig werden an Listen-Element die Eltern von weiteren Navigationselemten
            // sind Buttons zum Ein- bzw. Ausklappen angehangen
            'build' : function () {

                // Erzeuge die Buttons
                mobileNav.dropdowns.buttons = $('.js-gba-main-nav__item--has-menu > .gba-main-nav__text').after(mobileNav.dropdowns.html());
                mobileNav.dropdowns.buttons = mobileNav.dropdowns.buttons.next('button');

                // Verstecke alle Listenelement, ausser die der Ersten Ebene
                mobileNav.dropdowns.list.hide();

                // Verstecke bzw. zeige die Listenelemente auf Klick
                mobileNav.dropdowns.buttons.click(function () {

                        var $self = $(this);

                        // Ändere den Text der Buttons in Abhängigkeit von ihrer Sichtbarkeit
                        $self.toggleClass(mobileNav.dropdowns.buttonIsActiveClass).find('span').text(function (i, t) {
                            if (t === mobileNav.dropdowns.text.oeffnen) {
                                return mobileNav.dropdowns.text.schliessen
                            } else return mobileNav.dropdowns.text.oeffnen;
                        });

                        // Zeige/Verstecke das Untermenü
                        if ($self.next().is('div')) {
                            $self.next('div').children('ul').toggle();
                        } else {
                            $self.next().toggle();
                        }
                    });

                },
            'showActivePath' : function () {
                var $activeListItem = $('.gba-main-nav__item--is-active');

                // Wenn wir ein aktives Element haben ...
                if ($activeListItem.length) {

                    // ... suche Eltern Listen des Elements und zeige ihre Listen-Elemente
                    $activeListItem
                        .parents('.gba-main-nav__dropdown-wrapper, .gba-main-nav__list')
                        .prev(mobileNav.dropdowns.buttons)
                        .trigger('click');
                }
            }
        },
        'keyboardNav' : function () {
            $('.gba-main-nav__item--level-0').last().on('keydown', function (event) {
                var key = event.which.toString();

                // Fange den Fall ab, dass vom letzten fokussierbaren Element der Navigation in den Inhalt getabbt wird
                // und fokussiere stattdessen den Toggle-Button (Hamburger)
                if (key === '9') {
                    var $focusableElements = $(this).find('a:visible, button:visible');

                    if (event.target === $focusableElements[$focusableElements.length - 1]) {
                        event.preventDefault();

                        mobileNav.navToggle.focus();
                    }
                }
            });
        }
    };

    /**
     * Initiiere bzw. wechsle zwischen mobiler und "Desktop"-Navigation
     */
    function updateMainNavigation() {

        // Vorsicht 1! Mit include-media immer den BP-Value gegen die Viewport Breite testen, da sich Methoden wie z.B. im.greaterThan auf CSS MQs verlassen. Diese können sich durch die Kaskade aber gegenseitig aufheben, wenn sie nicht aufsteigend sortiert aufgelistet werden. Das ist insbesondere gefährlich wenn man auf Projekt-Ebene nur einen spezifischen BP überschreiben möchte. Z.B. den Navigation Breakpoint. Aus Sicht von include-media kann also nicht von einer "korrekten" Sortierung ausgegangen werden.
        // Vorsicht 2! Die Methode getValue() gibt unterhalb des ersten Breakpoints false zurück. Mobile Navigation Code wird dann nicht ausgeführt weil ((window.width() > 0) >= false) ist true.

        if (im.getValue('navigation-breakpoint', 'asNumber') && $window.width() >= im.getValue('navigation-breakpoint', 'asNumber')) {
            if (mobileNavInitiated) {
                mobileNav.hide();
            }

            if (!desktopNavInitiated) {
                desktopNavInitiated = desktopNav.init();
            }
        } else {
            if (desktopNavInitiated) {
                desktopNavInitiated = desktopNav.destroy();
            }

            if (!mobileNavInitiated) {
                mobileNavInitiated = mobileNav.init();
            } else {
                mobileNav.show();
            }
        }
    }

    // Initiiere Desktop- oder Mobil-Nav in Abhangigkeit des dedizierten 'navigation-breakpoint'
    $window.on('load', function () {
        updateMainNavigation();
    });
    $window.on('orientationchange', function() {
        // Verzögere die Breakpoint-bezogenen Aufrufe, da sie auf manchen älteren OS/Browserombinationen (z.B.
        // Android 4.4) schon losgehen bevor orientationchange beendet ist. Das resultiert in einem if-statement,
        // dass immer "true" bzw. immer "false" ist.
        debounce(updateMainNavigation(), 350);
    });

    // Aktualisiere die Hauptnavigation auch bei resize-Events, z.B. für den Fall, dass
    // Nutzer*innen durch extremen Seiten-Zoom in die mobile Ansicht wechseln
    $window.on('resize', function() {
        // Verzögere das Feuern onResize um Jank zu vermeiden
        debounce(updateMainNavigation(), 100);
    })

})(jQuery);
